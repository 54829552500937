/* Phone Screens */
@media (min-width: 576px) {
}

/* Tablet */
@media (min-width: 768px) {
  .services-view .services-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* Medium */
@media (min-width: 992px) {
  .services-view .services-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Desktop */
@media (min-width: 1024px) {
}

/* Large Screen */
@media (min-width: 1200px) {
  .services-view .services-list {
    grid-gap: 40px;
  }
}
