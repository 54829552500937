@charset "UTF-8";
/* CUSTOM FONTS */
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-SemiBold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-ExtraBold.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Hellix";
  src: url("../fonts/Hellix-Black.otf");
  font-weight: 900;
  font-style: normal;
}
/* BODY */
* {
  outline: none !important;
}

body {
  margin: 0;
  padding-left: 150px;
  padding-right: 50px;
  font-family: "Hellix";
  font-size: 17px;
  color: #000;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
}
.dark-mode {
  background-color: #000 !important;
  color: #fff;
}
/* LINKS */
a {
  color: #000;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

a:hover {
  text-decoration: underline;
  color: #000;
}

a:active {
  text-decoration: underline;
}
.dark-mode a {
  color: #fff;
}
.dark-mode a:hover {
  color: #fff;
}
/* HTML ELEMENTS */
img {
  max-width: 100%;
}

p {
  line-height: 1.5;
}

h1,
.h1 {
  font-size: 4vw;
}

/* FORM ELEMENTS */
input[type="text"] {
  width: 100%;
  height: 54px;
  border: 1px solid #eee;
  padding: 0 20px;
}

textarea {
  width: 100%;
  height: 160px;
  border: 1px solid #eee;
  padding: 15px 20px;
}

button[type="submit"],
input[type="button"] {
  height: 58px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
}
button[type="submit"],
input[type="button"] * {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
button[type="submit"] strong {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  height: 58px;
  border: 1px solid #000;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 30px;
  background: #000;
  color: #fff;
}
button[type="submit"] strong:hover {
  text-decoration: none;
}
button[type="submit"] b {
  width: 20px;
  height: 1px;
  background: #fff;
  display: inline-block;
  margin-left: 20px;
  margin-top: 8px;
}
button[type="submit"] i {
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #fff;
  opacity: 1;
  margin-top: 5px;
}
button[type="submit"]:hover b {
  width: 50px;
  margin-left: 20px;
}
button[type="submit"]:hover i {
  opacity: 0;
}
.hidden {
  display: none !important;
}
/* CUSTOM CONTAINER */
@media (min-width: 1240px) {
  .container {
    max-width: 1200px;
  }
}
/* CUSTOM ELEMENTS */
.color-dark {
  background-color: #000;
}
.color-dark .left-side ul {
  width: 100vh;
  line-height: 1;
  display: block;
  margin: 0;
  margin-top: 50vh;
  margin-left: -9px;
  padding: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
}
.color-dark .left-side ul li {
  display: inline-block;
  margin: 0 12px;
  padding: 0;
  list-style: none;
}
.color-dark .left-side ul li a {
  display: block;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  padding: 0 2px;
  color: #fff;
}
.color-dark .left-side ul li a:hover {
  text-decoration: none;
}
.color-dark .left-side ul li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 6px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.color-dark .left-side ul li a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 6px;
  height: 1px;
  width: 0;
  background: #fff;
  transition: width 0.25s ease;
}
.color-dark .left-side ul li a:hover:before {
  width: 100%;
  background: #fff;
  transition: width 0.25s ease;
}
.color-dark .left-side ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.color-dark .left-side .logo img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}
.color-dark .left-side .gotop img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.color-light {
  background-color: #fff;
}

.color-undefined {
  background-color: transparent;
}

.overflow {
  overflow: hidden;
}
.reviews p {
  font-size: 1.25rem !important;
  color: #f4f4f4;
}
.reviews h2 {
  font-size: 3.5rem;
  color: #f4f4f4;
}

/* ODOMETER */
.odometer {
  line-height: 1;
}
.odometer .odometer-digit {
  padding: 0;
}
.odometer .odometer-digit-inner {
  left: 0;
}

/* PAGINATION */
.pagination {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.pagination li.page-item {
  margin-right: 5px;
}
.pagination li.page-item:first-child a.page-link {
  padding-left: 0;
}
.pagination li.page-item.active a.page-link {
  border: 1px solid #000;
  background: none;
}
.pagination li.page-item a.page-link {
  border: none;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  border-radius: 50%;
}
.pagination li.page-item a.page-link:hover {
  background: none;
  opacity: 0.5;
}
.pagination li.page-item a.page-link:focus {
  border: none;
  background: none;
  box-shadow: none;
}

/* TRANSITION OVERLAY */
.transition-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  right: calc(-100% - 200px);
  bottom: 0;
  background: #000;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  visibility: hidden;
}
.transition-overlay:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 100vh 0;
  border-color: transparent #000 transparent transparent;
  position: absolute;
  left: -200px;
  top: 0;
}
.transition-overlay.active {
  right: 0;
  visibility: visible;
}

/* PRELOADER */
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  margin: auto;
  width: 100vw;
  height: 100vh;
  background: #000;
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  z-index: 99;
  transition-delay: 0.65s;
}
.preloader * {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
}
.preloader:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 100vh 0;
  border-color: transparent #000 transparent transparent;
  position: absolute;
  left: -200px;
  top: 0;
}
.preloader:after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 100vh 0 0 200px;
  border-color: transparent transparent transparent #000;
  position: absolute;
  right: -200px;
  top: 0;
}
.preloader .inner {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.preloader .inner .logo {
  display: inline-block;
  margin: 0;
  animation: fadeInUp ease 0.6s;
}
.preloader .inner .logo img {
  height: 165px;
}
.preloader .inner .percentage {
  width: 100%;
  font-size: 70vh;
  line-height: 1;
  font-weight: 800;
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0.05;
  transition-delay: 0.1s;
}

.page-loaded.preloader {
  left: calc(-100% - 200px);
  visibility: hidden;
}

.page-loaded.preloader .logo {
  transform: translateX(-100px);
  opacity: 0;
}

.page-loaded.preloader .percentage {
  margin-left: 100px;
  opacity: 0;
}

/* BURGER */
.burger {
  margin-top: 1px;
  z-index: 3;
  display: inline-block;
  padding: 0;
  border: none;
  outline: none;
  cursor: pointer;
}
.burger-icon {
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.burger-icon svg {
  color: #fff;
  height: 27px;
  width: 27px;
  transition: 0.3s;
}
.burger-icon svg:hover {
  transform: scale(1.2);
}

.isNavOpen {
  overflow: hidden;
}

.burger-svg__base {
  fill: #fff;
}
.dark-mode .burger-svg__base {
  fill: #000;
}
.burger-svg__bars {
  fill: #000;
}
.dark-mode .burger-svg__bars {
  fill: #fff;
}

/* NAVIGATION MENU */
.navigation-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  right: calc(-100% + -200px);
  top: 0;
  z-index: 2;
  background: #000;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  visibility: hidden;
}
.navigation-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 200px 100vh 0;
  border-color: transparent #000 transparent transparent;
  position: absolute;
  left: -200px;
  top: 0;
}
.navigation-menu.active {
  right: 0;
  visibility: visible;
}
.navigation-menu.active .inner {
  opacity: 1;
  transform: translateX(0);
}
.navigation-menu #map {
  width: 600px;
  height: 450px;
  display: none;
  border: none;
}
.navigation-menu .inner {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-evenly;
  padding: 0 10%;
  color: #fff;
  transform: translateX(40%);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-duration: 500ms;
  -webkit-transition-duration: 500ms;
  transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  transition-delay: 0.35s;
  /*padding-top: 80px;*/
}
.navigation-menu .inner .side-menu {
  width: auto;
  float: none;
  height: 100%;
  display: flex;
  align-items: center;
}
.navigation-menu .inner .side-menu ul {
  float: right;
  margin: 0;
  padding: 0;
  text-align: left;
}
.navigation-menu .inner .side-menu ul li {
  display: block;
  list-style: none;
  margin-bottom: 35px;
}
.navigation-menu .inner .side-menu ul li:last-child {
  margin-bottom: 0;
}
.navigation-menu .inner .side-menu ul li a {
  font-size: 72px;
  color: #fff;
  font-weight: 600;
}
.navigation-menu .inner .side-menu ul li a > span {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}
.navigation-menu .inner .sides {
  flex: none;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  /* border-left: 1px solid rgba(255, 255, 255, 0.4); */
}
.navigation-menu .inner .sides:last-child {
  padding-left: 50px;
}
.navigation-menu .inner h2 {
  display: block;
  margin-bottom: 20px;
  line-height: 1.7;
  font-size: 25px;
}
.navigation-menu .inner h2 strong {
  font-weight: 600;
}
.navigation-menu .inner figure {
  display: block;
  margin: 0;
  background: #fff;
  padding: 6px;
  position: relative;
}
.navigation-menu .inner figure a {
  width: 50px;
  height: 50px;
  line-height: 44px;
  position: absolute;
  right: 6px;
  top: 6px;
  background: #fff;
  text-align: center;
}
.navigation-menu .inner address {
  display: block;
  font-size: 19px;
}
.navigation-menu .inner address a {
  display: block;
  color: #fff;
  text-decoration: underline;
}
.navigation-menu .inner .sides {
  padding: 0;
}
.navigation-menu .inner .sides ul {
  padding: 0 !important;
  margin: 0;
}
.navigation-menu .inner .sides ul li {
  list-style: none;
  padding: 0 !important;
  margin: 30px 0;
}
.navigation-menu .inner .sides ul li:last-child {
  margin-bottom: 0;
}
.navigation-menu .inner .sides ul li a,
.navigation-menu .inner .sides ul li address {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  display: block;
  margin-top: 8px;
  font-size: 17px;
}
header.active .main-menu,
header.active .phone {
  display: none;
}

/* REVEAL EFFECT */
.reveal-effect {
  float: left;
  overflow: hidden;
  position: relative;
}

.reveal-effect > * {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  position: relative;
}

.reveal-effect > * {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.reveal-effect {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.reveal-effect {
  -webkit-transition-duration: 1.2s;
  transition-duration: 1.2s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

.reveal-effect.animated {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
}

.reveal-effect.animated * {
  -webkit-animation-name: show-img-1;
  animation-name: show-img-1;
}

.reveal-effect:after {
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect:after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.reveal-effect:after {
  -webkit-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(0.05, 0);
  transform: scale(0.05, 0);
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.reveal-effect.masker:after {
  background-color: #eee;
}

.reveal-effect.animated:after {
  -webkit-animation-name: slide-bg-2;
  animation-name: slide-bg-2;
}

/* TITLES */
.titles {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  padding-right: 50%;
  margin-bottom: 50px;
}
.titles h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
  letter-spacing: 2px;
}
.titles p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 0;
  font-weight: 300;
}

/* CUSTOM LINK */
.custom-link {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.custom-link * {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.custom-link a {
  display: inline-block;
  margin-right: 15px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
}
.custom-link a:hover {
  text-decoration: none;
}
.custom-link span {
  width: 20px;
  height: 1px;
  background: #000;
  display: inline-block;
  margin: 0;
}
.custom-link i {
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #000;
  opacity: 1;
}
.custom-link:hover a {
  margin-left: 20px;
}
.custom-link.back-btn a {
  margin-left: 10px;
  text-transform: unset;
  font-weight: 400;
}
.custom-link.back-btn i {
  transform: rotate(180deg);
}
.custom-link.back-btn:hover a {
  margin-left: 20px;
}
.custom-link:hover span {
  width: 50px;
  margin-left: 20px;
}

.custom-link.back-btn {
  margin-right: 0;
  text-transform: unset;
}

.custom-link.back-btn:hover span {
  width: 24px;
  margin-left: unset;
}
/* .custom-link:hover i {
  opacity: 0;
} */

/* CUSTOM BUTTON */
.custom-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-align: center;
}
.custom-btn * {
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.custom-btn a {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 58px;
  font-size: 13px;
  font-weight: 600 !important;
  text-transform: uppercase;
  padding: 0 30px;
  padding-top: 18px;
  color: #fff;
  transition: 0.5s;
  background-size: 200% auto;
  background-image: linear-gradient(90deg, #f31c76 0%, #8d12b4 50%);
}
.custom-btn a:hover {
  text-decoration: none;
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.custom-btn span {
  width: 20px;
  height: 1px;
  background: #fff;
  display: inline-block;
  margin-left: 20px;
  margin-top: 8px;
}
.custom-btn i {
  width: 0;
  height: 0;
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  border-style: solid;
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #fff;
  opacity: 1;
  margin-top: 5px;
}
.custom-btn:hover span {
  width: 50px;
  margin-left: 20px;
}
.custom-btn:hover i {
  opacity: 0;
}
/* .custom-btn.white a {
  background: #fff;
  color: #000 !important;
  border: 1px solid #fff;
} */
/* .custom-btn.white span {
  background: #000;
} */
/* .custom-btn.white i {
  border-color: transparent transparent transparent #000;
} */

/* HEADER */
.header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.dark-mode .navbar {
  background: #000;
}
.header .navbar {
  width: 100%;
  margin-bottom: 0;
}
.header .page-title {
  width: 100%;
  margin-bottom: 100px;
  margin-top: 50px;
}
.header .page-title h2 {
  font-weight: 700;
  font-size: 4vw;
  margin-bottom: 20px;
}
.header .page-title h4 {
  padding-right: 40%;
  line-height: 1.7;
}
.header .headlines {
  width: 100%;
  margin-bottom: 100px;
  margin-top: 50px;
  position: relative;
}
.dark-mode .header .headlines h1 {
  font-size: 5rem;
}
#with-us {
  font-family: "IBM Plex Serif", serif;
  font-style: italic;
  text-decoration: underline;
  font-weight: normal;
}
#cursor {
  margin: 0;
  display: inline-block;
}
.dark-mode .header .headlines p {
  color: #898989;
  font-size: 2rem;
  line-height: 1.4;
  max-width: 770px;
}
.header .headlines .container {
  z-index: 1;
  position: relative;
}
.banner-video {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}
.banner-video::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 32%,
    rgba(255, 255, 255, 0.56) 100%
  );
}
.header .headlines h1 {
  font-size: 4vw;
  font-weight: 800;
  line-height: 1;
  margin: 0;
  padding-bottom: 5px;
  margin-top: 50px;
}
.header .headlines .custom-btn {
  margin-top: 50px;
}

/* NAVBAR */
.navbar {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0;
  position: relative;
  z-index: 9;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.navbar.hide {
  transform: translateY(-100px);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.navbar.light {
  color: #fff;
}
.navbar.light .logo a img {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}
.navbar.light .main-menu ul li a {
  color: #fff;
}
.navbar.light .main-menu ul li a:after {
  background: #fff;
}
.navbar.light .main-menu ul li a:hover:before {
  background: #fff;
}
.navbar .logo {
  margin-left: 0;
}
.navbar .logo a {
  margin: 0;
}
.navbar .logo a img {
  height: 56px;
}
.navbar .phone {
  margin-left: 50px;
}
.navbar .main-menu {
  margin-left: auto;
}
.dark-mode .navbar .main-menu {
  margin-left: unset;
}
.navbar .main-menu ul {
  margin: 0;
  padding: 0;
}
.navbar .main-menu ul li {
  display: inline-block;
  margin: 0 20px;
  padding: 0;
  position: relative;
}
.navbar .main-menu ul li a {
  display: block;
  font-size: 15px;
  font-weight: 600;
  position: relative;
  padding: 0 2px;
}
.dark-mode .navbar {
  justify-content: space-between;
}
.dark-mode .navbar .main-menu ul li a {
  color: #ffffff;
}
.navbar .main-menu ul li a:hover {
  text-decoration: none;
}
.navbar .main-menu ul li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 9px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.navbar .main-menu ul li a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 9px;
  height: 1px;
  width: 0;
  background: #000;
  transition: width 0.25s ease;
}
.navbar .main-menu ul li a:hover:before {
  width: 100%;
  background: #000;
  transition: width 0.25s ease;
}
.navbar .main-menu ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.navbar .hamburger-menu {
  margin-right: 0;
  margin-left: 30px;
}

/* LEFT SIDE */
.left-side {
  width: 150px;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
}

.left-side .logo {
  width: 100%;
  display: block;
  height: 42px;
  text-align: center;
  margin-top: 35px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  opacity: 0;
  transform: translateY(-30px);
}
.left-side .logo img {
  height: 42px;
}
.left-side .logo.sticky {
  opacity: 1;
  transform: translateY(0);
}
.left-side ul {
  width: 100vh;
  line-height: 1;
  display: block;
  margin: 0;
  margin-top: 50vh;
  margin-left: -9px;
  padding: 0;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  position: absolute;
  left: 50%;
  top: 50%;
  text-align: center;
}
.left-side ul li {
  display: inline-block;
  margin: 0 11px;
  padding: 0;
  list-style: none;
}
.left-side ul li a {
  display: block;
  font-size: 13px;
  font-weight: 600;
  position: relative;
  padding: 0 2px;
}
.dark-mode .left-side ul li a {
  color: #fff;
}
.left-side ul li a:hover {
  text-decoration: none;
}
.left-side ul li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 6px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.left-side ul li a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 6px;
  height: 1px;
  width: 0;
  background: #000;
  transition: width 0.25s ease;
}
.left-side ul li a:hover:before {
  width: 100%;
  background: #000;
  transition: width 0.25s ease;
}
.left-side ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.left-side .gotop {
  width: 50px;
  padding: 14px;
  position: absolute;
  left: 50px;
  bottom: 35px;
  display: none;
}
.left-side .gotop img {
  display: block;
}

/* SLIDER */
.slider {
  width: calc(100% + 50px);
  height: 560px;
  display: flex;
  flex-wrap: wrap;
  background: #000;
  margin-right: -50px;
  overflow: hidden;
  position: relative;
}
.swiper-container {
  background-color: #c9ecff;
  padding: 2rem;
  border-radius: 0;
  color: #0c0c0c;
}
.swiper-container .main-title {
  padding-left: 0;
}
.slider:hover .swiper-container .swiper-slide {
  margin-left: -10px;
}
.slider:hover .swiper-button-next span {
  width: 100%;
}
.slider .swiper-container {
  width: 100%;
  height: 100%;
  position: relative;
}
.slider .swiper-container .swiper-slide {
  width: calc(100% - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  opacity: 0.3;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.slider .swiper-container .swiper-slide-active {
  opacity: 1;
  z-index: 2;
}
.slider .swiper-container .swiper-slide-active .slide-inner {
  opacity: 1;
}
.slider .swiper-container .slide-inner {
  width: 100%;
  padding: 0 100px;
  padding-right: 40%;
  opacity: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.slider .swiper-container .slide-inner figure {
  display: block;
  margin-bottom: 10px;
}
.slider .swiper-container .slide-inner figure img {
  max-width: 120px;
}
.slider .swiper-container .slide-inner h2 {
  font-size: 52px;
  font-weight: 600;
  color: #fff;
  margin-left: -3px;
}
.slider .swiper-container .slide-inner .link {
  display: block;
  margin-top: 50px;
}
.slider .swiper-container .slide-inner .link a {
  display: inline-block;
  font-weight: 600;
  color: #fff;
  font-size: 13px;
  position: relative;
}
.slider .swiper-container .slide-inner .link a:before {
  content: "";
  width: 100%;
  height: 6px;
  background: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.3;
}
.slider .swiper-container .slide-inner .link a:after {
  content: "";
  width: 0;
  height: 6px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.slider .swiper-container .slide-inner .link a:hover {
  text-decoration: none;
}
.slider .swiper-container .slide-inner .link a:hover:after {
  width: 100%;
}

/* SWIPER BUTTONS */
.swiper-button-next {
  width: 80px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: auto;
  right: 20px;
  top: 0;
  z-index: 2;
  background: none;
  text-align: right;
}
.swiper-button-next span {
  width: 50%;
  height: 1px;
  margin-left: auto;
  background: #fff;
  display: inline-block;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.swiper-button-next b {
  font-weight: 600;
  display: block;
  font-size: 12px;
  color: #fff;
  margin-top: 5px;
}

/* SWIPER PAGINATION */
.swiper-pagination-bullet {
  opacity: 1;
  background: rgba(0, 0, 0, 0.2);
  position: relative;
  height: 40px;
  width: 40px;
  background: none;
  text-align: center;
  transform: scaleX(-1) scale(0.7);
  margin: 0;
}
.dark-mode .swiper-pagination-bullet {
  opacity: 1;
  background: rgba(255, 255, 255, 0.9);
}
.swiper-pagination-bullet:before {
  content: "";
  width: 100%;
  height: 100%;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: none;
}
.swiper-pagination-bullet-active:after {
  content: "";
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -7px;
  margin-top: -7px;
}

.swiper-pagination-bullet svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.swiper-pagination-bullet.swiper-pagination-bullet-active svg circle {
  animation-play-state: running;
  stroke-width: 3px;
  animation: countdown 6s linear forwards;
}

.swiper-pagination-bullet svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 0;
  stroke: #fff;
  fill: none;
  animation: none;
  animation-play-state: paused;
  animation-fill-mode: none;
}

/* PAGE HEADER */
.page-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #000;
}
.page-header .video-bg {
  width: 100%;
}
.page-header .video-bg video {
  width: 100%;
  display: flex;
}
.page-header iframe {
  width: 100%;
  height: 500px;
  display: block;
  border: none;
}
.page-header figure {
  width: 100%;
  margin: 0;
}
.page-header figure img {
  width: 100%;
}

/* ABOUT INTRO */
.about-intro {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
}
.about-intro h2 {
  font-size: 4vw;
  font-weight: 700;
  margin-bottom: 50px;
}
.about-intro p {
  line-height: 2;
  margin: 0;
}
.about-intro p:nth-child(2) {
  margin-bottom: 30px;
  font-weight: 600;
}
.rating .rating-box {
  display: flex;
  text-decoration: none;
  padding: 1rem 1.2rem;
  margin-bottom: 1.5rem;
  line-height: 1;
  border: 1px solid #000;
  border-radius: 2rem;
}

/* MAIN SERVICES */
.main-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  text-align: center;
}
.main-services .titles {
  padding: 0 20%;
  color: #fff;
}
.main-services figure {
  display: inline-block;
  margin-bottom: 0;
}
.main-services figure img {
  height: 60px;
  display: inline-block;
}
.main-services article {
  width: 100%;
  float: left;
  margin-bottom: 50px;
  color: #fff;
  position: relative;
  padding: 0 30px;
}
.main-services article span {
  display: block;
  font-weight: 700;
  font-size: 40px;
  opacity: 0.1;
}
.main-services article h4 {
  display: block;
  margin-top: -25px;
  font-weight: 600;
  font-size: 18px;
}

/* WORKS */
.works {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 80px 0;
}
.works .titles {
  display: block;
  padding-right: 15%;
  margin-bottom: 20px;
}
.works .titles h2 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.works .titles p {
  font-size: 20px;
  line-height: 1.5;
  margin-bottom: 40px;
  font-weight: 300;
}
.works ul {
  margin-bottom: 0;
  margin-left: -30px;
  margin-right: -30px;
  padding: 0;
}
.works ul li {
  width: 50%;
  margin: 40px 0;
  padding: 0 30px;
  list-style: none;
}

/* PROJECT BOX */
.project-box {
  width: 100%;
  display: block;
}
.project-box .project-image {
  width: 100%;
  display: block;
}
.project-box .project-image a {
  display: block;
}
.project-box .project-image a img {
  width: 100%;
}
.project-box .project-content {
  width: 100%;
  display: block;
}
.project-box .project-content h3 {
  font-size: 24px;
  display: block;
  font-weight: 700;
}
.project-box .project-content h3 a {
  display: block;
}
.project-box .project-content h3 a:hover {
  text-decoration: none;
}
.project-box .project-content small {
  display: block;
}
.project-box .custom-link a {
  font-weight: 400;
  text-transform: none;
}

/* CASE DETAIL */
.case-detail {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 120px 0;
}
.case-detail figure {
  display: block;
  margin-bottom: 50px;
}
.case-detail figure img {
  width: 100%;
}
.case-detail h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.case-detail h5 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
}
.case-detail h3 {
  font-size: 50px;
  font-weight: 800;
  margin-top: 100px;
}
.case-detail p {
  display: block;
  margin-bottom: 50px;
}

/* FEATURED SERVICES */
.featured-services {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  color: #fff;
}
.featured-services .col-lg-3:nth-child(2n + 2) {
  margin-top: 134px;
}
.featured-services figure {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  margin: 15px 0;
  border: 1px solid rgba(255, 255, 255, 0.05);
}
.featured-services figure:hover {
  background: #fff;
}
.featured-services figure:hover img {
  margin: 0;
  filter: invert(100%);
}
.featured-services figure:hover figcaption {
  color: #000;
}
.featured-services figure img {
  opacity: 0.1;
  padding: 0 35%;
  filter: blur(10px);
}
.featured-services figure figcaption {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 20px;
  text-align: center;
}
.featured-services figure figcaption span {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 10px;
}
.featured-services figure figcaption h4 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0;
}

/* RECENT NEWS */
.recent-news {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  padding-right: 0;
}
.dark-mode .recent-news {
  overflow: hidden;
}
.recent-news .row {
  margin-left: -25px;
  margin-right: -25px;
}
.recent-news .col-lg-4 {
  padding-left: 25px;
  padding-right: 25px;
}
.recent-news .col-12 {
  padding-left: 25px;
  padding-right: 25px;
}
.recent-news .inner h3 {
  font-size: 20px;
}
.recent-news .content-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 30px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  position: relative;
}
.recent-news .content-box:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
/*.recent-news .content-box:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: -1px;
  height: 1px;
  width: 0;
  background: #000;
  transition: width 0.25s ease;
}
.recent-news .content-box:hover:before {
  width: 100%;
  background: #000;
  transition: width 0.25s ease;
}
.recent-news .content-box:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.recent-news .content-box.selected {
  padding-bottom: 80px;
}*/
.recent-news .content-box.selected h3 {
  font-size: 4vw;
  font-weight: 600;
}
.recent-news .content-box small {
  opacity: 0.5;
  margin-bottom: 10px;
}
.recent-news .content-box h3 {
  display: block;
  margin-bottom: 20px;
  padding-bottom: 20px;
}
.recent-news .content-box h3 a {
  display: block;
}
.recent-news .content-box h3 a:hover {
  text-decoration: none;
}
.recent-news .content-box span {
  display: block;
  font-weight: 600;
}

/* OUR AWARDS */
.our-awards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.dark-mode .our-awards .titles {
  padding: 0;
  text-align: left !important;
}
.dark-mode .our-awards .titles h3,
.dark-mode .logos .titles h3,
.recent-news h3 {
  font-size: 2.5rem !important;
}
.our-awards .titles {
  text-align: center;
  padding: 0 20%;
}
.our-awards ul {
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: center;
}
.our-awards ul li {
  width: 20%;
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
.our-awards ul li:last-child figure {
  border-right: 0;
}
.our-awards ul li figure {
  width: 100%;
  margin-bottom: 40px;
  padding: 0 30%;
  border-right: 1px solid rgba(40, 40, 40, 0.1);
}
.our-awards ul li h5 {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 15px;
}
.our-awards ul li small {
  display: block;
  opacity: 0.6;
  margin-bottom: 5px;
}
.our-awards ul li .odometer {
  font-size: 60px;
  font-weight: 800;
}
.our-awards ul li i {
  font-style: normal;
  font-size: 60px;
  font-weight: bold;
  position: relative;
  top: 20px;
  line-height: 0;
}

/* SHOWREEL */
.showreel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.showreel .video {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.showreel .video h2 {
  width: 100%;
  height: 200px;
  line-height: 200px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -100px;
  text-align: center;
  color: #fff;
  font-size: 4vw;
  font-weight: 800;
}
.showreel .video video {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
  float: left;
}

/* TEAM */
.team {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  color: #fff;
}
.team figure {
  display: block;
  margin: 0;
}
.team figure img {
  width: 100%;
  display: block;
  margin-bottom: 20px;
}
.management.team figure img {
  margin-bottom: 0;
}
.management.team figure figcaption,
.team .team-box {
  color: #000;
}
.team .team-box .member-img {
  width: 170px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #000;
  color: #000;
  font-size: 3rem;
  margin: auto;
  margin-bottom: 2rem;
}
.team .team-box .member-img.primary {
  background: #000;
  color: #fff;
}
.team .team-box .member-img.secondary {
  background: #ededed;
}
.team figure figcaption {
  display: block;
  margin: 0;
}
.team figure figcaption span,
.team .team-box .member-title span {
  display: block;
  font-size: 14px;
  opacity: 0.5;
  margin-bottom: 50px;
}
.team figure figcaption h5 {
  display: block;
  font-weight: 600;
  margin-bottom: 0;
}
.team figure figcaption a {
  color: #fff;
  margin-right: 10px;
  margin-bottom: 10px;
}
.text-black {
  color: #000;
}

.company-logos {
  padding: 90px 0 120px 0px;
}
.company-logos .swiper {
  padding: 15px 0px;
}
.company-logos .item {
}
.company-logos .item img {
  height: 32px;
  width: 100%;
  object-fit: contain;
}
.company-logos .item.SylphAI img {
  height: 58px;
  margin-top: -13px;
}
.company-logos .item.SteadyStudios img {
  height: 40px;
}

/* LOGOS */
.logos {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.dark-mode .company.logos {
  padding: 150px 0;
}
.company.logos {
  padding-top: 90px;
}
.company.logos ul {
  border: 0;
}
.company.logos ul li {
  border: 0;
  cursor: pointer;
  margin-bottom: 20px;
}
.company.logos ul li img {
  height: 30px;
  margin: 0;
  opacity: 1;
  transition: all 0.4s;
}
.company.logos ul.dark li {
  margin-bottom: 50px;
}
.company.logos ul.dark li img {
  height: 25px;
}

.company.logos ul li:hover img {
  opacity: 1;
}
.company.logos ul li figure img {
  height: auto;
  cursor: pointer;
}
.logos ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  position: relative;
  border-left: 1px solid rgba(40, 40, 40, 0.1);
  border-top: 1px solid rgba(40, 40, 40, 0.1);
  justify-content: space-evenly;
}
.logos ul li {
  width: 20%;
  float: left;
  list-style: none;
  border-right: 1px solid rgba(40, 40, 40, 0.1);
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
  text-align: center;
}
.logos ul li:hover figure {
  opacity: 1;
}
.logos ul li figure {
  display: block;
  height: 80px;
  margin: 30px 0;
  opacity: 0.6;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  position: relative;
}
.dark-mode .company.logos ul li figure {
  opacity: 1;
  height: 30px;
}
.dark-mode .company.logos ul li figure img {
  height: 30px;
}
.dark-mode .company.logos ul li:hover figure {
  opacity: 0.6;
}
.logos ul li figure img {
  display: none;
  height: 80px;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}
.dark-mode .company.logos ul li figure img {
  display: block;
}
/* NEW CASE STUDY HOME PAGE*/
.new-case-study h2 {
  font-size: 3.75rem;
}
.new-case-study p.large {
  font-size: 1.5rem;
}
.dark-mode .new-case-study a {
  color: #000 !important;
}
.grey {
  color: rgba(54, 54, 54, 0.6);
}
/* BLOG */
.post-header {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.post-header .post-image {
  width: 100%;
  margin: 0;
}
.post-header .post-image img {
  width: 100%;
}

.post-body {
  width: 100%;
  margin-bottom: 50px;
}
.post-body .avatar {
  width: 100%;
  margin-bottom: 20px;
  font-size: 13px;
}
.post-body .avatar img {
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
}
.post-body .avatar a {
  text-decoration: underline;
}
.post-body .post-title {
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 3vw;
}
.post-body .post-date {
  display: block;
  margin-bottom: 30px;
  opacity: 0.5;
}
.post-body .post-intro {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.7;
}
.post-body .image-left {
  width: 50%;
  float: left;
  margin-right: 30px;
  margin-bottom: 20px;
  margin-top: 5px;
}
.post-body .image-left img {
  width: 100%;
}
.post-body .image-right {
  width: 50%;
  float: right;
  margin-left: 30px;
  margin-bottom: 20px;
  margin-top: 5px;
}
.post-body .image-right img {
  width: 100%;
}
.post-body .image-full {
  width: 100%;
  display: inline-block;
  margin: 30px 0;
}
.post-body .image-full img {
  width: 100%;
}
.post-body h5 {
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 15px;
}
.post-body ul {
  padding-left: 20px;
  margin-bottom: 30px;
}
.post-body ul li {
  margin-bottom: 5px;
}
.post-body blockquote {
  display: inline-block;
  padding: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
  background: #000;
  position: relative;
  color: #fff;
  background: url(../images/quote-bg.svg) left top no-repeat #000;
  background-size: contain;
}
.post-body blockquote p {
  display: block;
}
.post-body blockquote h5 {
  font-weight: 600;
  margin: 0;
}

.post-navigation {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  background: #f9f9f9;
}
.post-navigation a {
  font-weight: 600;
  font-size: 18px;
  padding: 40px 25px;
}
.post-navigation a:nth-child(1) {
  width: 50%;
  float: left;
  border-right: 1px solid #eee;
}
.post-navigation a:nth-child(2) {
  width: 50%;
  float: right;
  padding-left: 25px;
}

.blog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
}
.blog .post {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 150px;
}
.blog .post .post-image {
  width: 100%;
  margin-bottom: 30px;
}
.blog .post .post-image img {
  width: 100%;
}
.blog .post .post-content {
  width: 100%;
  margin: 0;
}
.blog .post .post-content .avatar {
  width: 100%;
  margin-bottom: 20px;
  font-size: 13px;
}
.blog .post .post-content .avatar img {
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
}
.blog .post .post-content .avatar a {
  text-decoration: underline;
}
.blog .post .post-content .post-title {
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 3vw;
}
.blog .post .post-content .post-date {
  display: block;
  margin-bottom: 30px;
  opacity: 0.5;
}
.blog .post .post-content .post-intro {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.7;
}
.blog .post .post-content .post-link {
  display: inline-block;
  font-weight: 700;
  padding-bottom: 8px;
  position: relative;
}
.blog .post .post-content .post-link:hover {
  text-decoration: none;
}
.blog .post .post-content .post-link:hover:after {
  width: 0;
}
.blog .post .post-content .post-link:before {
  content: "";
  width: 100%;
  height: 3px;
  background: #eee;
  position: absolute;
  left: 0;
  bottom: 0;
}
.blog .post .post-content .post-link:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #000;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.blog .sidebar {
  width: calc(100% - 50px);
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  border: 1px solid rgba(40, 40, 40, 0.1);
  padding: 25px;
}
.blog .sidebar .widget {
  width: 100%;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.blog .sidebar .widget:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.blog .sidebar .widget .title {
  display: block;
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.blog .sidebar .widget .title:after {
  content: "";
  width: 30%;
  height: 3px;
  background: #000;
  position: absolute;
  left: -25px;
  bottom: 0;
}
.blog .sidebar .widget form {
  display: block;
}
.blog .sidebar .widget form input[type="text"] {
  margin-bottom: 10px;
}
.blog .sidebar .widget form button[type="submit"] {
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  background: #000;
  padding: 0 30px;
}
.blog .sidebar .widget .categories {
  display: block;
  margin: 0;
  padding: 0;
}
.blog .sidebar .widget .categories li {
  display: block;
  margin: 0;
  padding: 3px 0;
  list-style: none;
}
.blog .sidebar .widget .categories li:before {
  content: "";
  width: 5px;
  height: 5px;
  float: left;
  border-radius: 50%;
  background: #000;
  margin-right: 15px;
  margin-top: 11px;
}
.blog .sidebar .widget .categories li span {
  float: right;
  opacity: 0.5;
}
.blog .sidebar .widget .tags {
  display: block;
  margin: 0;
  padding: 0;
}
.blog .sidebar .widget .tags li {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 0;
  list-style: none;
}
.blog .sidebar .widget .tags li a {
  display: inline-block;
  border: 1px solid rgba(40, 40, 40, 0.1);
  line-height: 30px;
  font-size: 14px;
  padding: 0 10px;
}
.blog .sidebar .widget .tags li a:hover {
  text-decoration: none;
  border: 1px solid #000;
  background: #000;
  color: #fff;
}
.blog .sidebar .widget .works {
  display: block;
  margin: 0;
  margin-left: -5px;
  margin-right: -5px;
  padding: 0;
}
.blog .sidebar .widget .works li {
  width: 33.33333%;
  float: left;
  margin: 0;
  padding: 5px;
  list-style: none;
}

/* POST STICKY */
.post-sticky {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}
.post-sticky .post-image {
  width: 100%;
  margin-bottom: 0;
}
.post-sticky .post-image img {
  width: 100%;
  opacity: 0.5;
}
.post-sticky .post-content {
  width: 100%;
  margin: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  color: #fff;
  padding: 50px;
  padding-right: 30%;
}
.post-sticky .post-content .avatar {
  width: 100%;
  margin-bottom: 20px;
  font-size: 13px;
}
.post-sticky .post-content .avatar img {
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
}
.post-sticky .post-content .avatar a {
  text-decoration: underline;
  color: #fff;
}
.post-sticky .post-content .post-title {
  display: block;
  margin-bottom: 15px;
  font-weight: 700;
  font-size: 4vw;
}
.post-sticky .post-content .post-title a {
  display: block;
  color: #fff;
}
.post-sticky .post-content .post-date {
  display: block;
  margin-bottom: 30px;
}
.post-sticky .post-content .post-intro {
  display: block;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.7;
}
.post-sticky .post-content .post-link {
  display: inline-block;
  font-weight: 700;
  padding-bottom: 8px;
  position: relative;
  color: #fff;
}
.post-sticky .post-content .post-link:hover {
  text-decoration: none;
}
.post-sticky .post-content .post-link:hover:after {
  width: 0;
}
.post-sticky .post-content .post-link:before {
  content: "";
  width: 100%;
  height: 3px;
  background: rgba(255, 255, 255, 0.2);
  position: absolute;
  left: 0;
  bottom: 0;
}
.post-sticky .post-content .post-link:after {
  content: "";
  width: 100%;
  height: 3px;
  background: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

/* WORK WITH US */
.work-with-us {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
  color: #fff;
}
.work-with-us h6 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 20px;
}
.work-with-us h2 {
  font-size: 4vw;
  font-weight: 800;
  margin-bottom: 50px;
}
.work-with-us p {
  display: block;
  margin: 0;
  font-size: 19px;
}
.work-with-us a {
  color: #fff;
  text-decoration: underline;
}
.work-with-us small {
  display: block;
  margin: 15px 0;
}

/* CONTACT */
.contact {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-top: 150px;
}
.contact h5 {
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}
.contact address {
  display: block;
  margin-bottom: 50px;
}
.contact address p {
  margin: 0;
}
.contact address a {
  text-decoration: underline;
}
.contact .contact-form {
  display: block;
  margin-top: 50px;
}
.contact .contact-form h5 {
  margin-bottom: 40px;
}
.contact .contact-form #contact {
  display: block;
}
.contact .contact-form #contact .form-group {
  display: block;
  position: relative;
  margin-bottom: 65px;
}
.contact .contact-form #contact .form-group span {
  width: 100%;
  line-height: 54px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0 20px;
  z-index: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.contact .contact-form #contact .form-group span.label-up {
  font-size: 13px;
  line-height: 16px;
  top: -20px;
  font-weight: 600;
}
.contact .contact-form #contact .form-group input[type="text"] {
  background: none;
  position: relative;
  z-index: 2;
}

.cb-input_light {
  position: relative;
  display: block;
  line-height: 0;
  overflow: hidden;
  contain: content;
}
.cb-input_light-line {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 138px;
  width: 300%;
  -webkit-transform: translateX(-66.66%);
  -moz-transform: translateX(-66.66%);
  -ms-transform: translateX(-66.66%);
  -o-transform: translateX(-66.66%);
  transform: translateX(-66.66%);
  opacity: 0.1;
  background-image: url(../images/download.svg);
  background-position: center bottom;
  pointer-events: none;
  -webkit-transition: opacity 0.4s,
    -webkit-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
  transition: opacity 0.4s, -webkit-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
  -o-transition: opacity 0.4s, -o-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
  -moz-transition: transform 0s cubic-bezier(0, 0.25, 0.5, 1), opacity 0.4s,
    -moz-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
  transition: transform 0s cubic-bezier(0, 0.25, 0.5, 1), opacity 0.4s;
  transition: transform 0s cubic-bezier(0, 0.25, 0.5, 1), opacity 0.4s,
    -webkit-transform 0s cubic-bezier(0, 0.25, 0.5, 1),
    -moz-transform 0s cubic-bezier(0, 0.25, 0.5, 1),
    -o-transform 0s cubic-bezier(0, 0.25, 0.5, 1);
}

.cb-input_light input:focus + .cb-input_light-line {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  -webkit-transition-duration: 0.8s, 0.4s;
  -moz-transition-duration: 0.8s, 0.4s;
  -o-transition-duration: 0.8s, 0.4s;
  transition-duration: 0.8s, 0.4s;
}
.cb-input_light input:hover + .cb-input_light-line,
.cb-input_light textarea:hover + .cb-input_light-line {
  opacity: 0.6;
}
.cb-input_light input:hover + .cb-input_light-line,
.cb-input_light textarea:hover + .cb-input_light-line {
  opacity: 0.6;
}
.cb-input_light input,
.cb-input_light textarea {
  position: relative;
  margin: 0;
  padding: 15px 0;
  width: 100%;
  z-index: 1;
  color: #000;
  background: 0 0;
  border: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  outline: 0;
  resize: none;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.03em;
  line-height: 100%;
  -webkit-transition: all 0.3s, height 0s;
  -o-transition: all 0.3s, height 0s;
  -moz-transition: all 0.3s, height 0s;
  transition: all 0.3s, height 0s;
}

.contact .contact-form #contact .form-group input:focus + span {
  font-size: 13px;
  line-height: 16px;
  top: -20px;
  font-weight: 600;
}
.contact .contact-form #contact .form-group textarea {
  background: none;
  position: relative;
  z-index: 2;
}
.contact .contact-form #contact .form-group textarea:focus + span {
  font-size: 13px;
  line-height: 16px;
  top: -20px;
  font-weight: 600;
}
.contact .contact-form #contact .form-group label.error {
  color: red;
  margin-top: 5px;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 13px;
}
.contact .contact-form #success,
.contact .contact-form #error {
  display: none;
  float: left;
}
.contact .contact-form #error {
  background: red;
  color: #fff;
}
.contact .contact-form #success {
  background: green;
  color: #fff;
}
.contact .contact-form .alert {
  border: none;
  border-radius: 0;
  padding: 20px 30px;
}
.contact .contact-form #contact .form-group {
  margin-bottom: 45px;
}
.contact .contact-form #contact .form-group.form-group2 p {
  font-size: 32px;
  color: #ccc;
}
.contact .contact-form #contact .form-group.form-group2 label {
  position: relative;
  margin: 20px 0;
  margin-right: 1%;
  overflow: hidden;
}
.contact .contact-form #contact .form-group.form-group2 label i {
  border: 1px solid #ccc;
  position: relative;
  font-weight: 500;
  padding: 15px 31px;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  font-style: normal;
  display: block;
  width: 100%;
  font-size: 20px;
  color: #fff;
}
.contact .contact-form #contact .form-group.form-group2 label i > b {
  font-weight: 500;
  display: block;
  transition: 300ms ease-in-out;
}
.contact .contact-form #contact .form-group.form-group2 label i > .hover {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  text-align: center;
  transition: 300ms ease-in-out;
}
.contact
  .contact-form
  #contact
  .form-group.form-group2
  label:hover
  i
  > .withoutHover {
  transform: translateY(-70px);
}
.contact .contact-form #contact .form-group.form-group2 label:hover i > .hover {
  top: 15px;
}

.contact .contact-form #contact .form-group.form-group2 label input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.contact
  .contact-form
  #contact
  .form-group.form-group2
  label
  input:checked
  ~ i {
  background: #fff;
  border-color: #fff;
  color: #000;
}

.contact .contact-form #contact .form-group input,
.contact .contact-form #contact .form-group textarea {
  border: 0;
  border-bottom: 0px solid #ccc;
  font-size: 32px;
  height: auto;
  padding: 21px 0px;
  color: #fff;
}
.contact .contact-form #contact .form-group textarea {
  border: 1px solid #ccc;
  padding: 21px 20px;
  margin-top: 22px;
}
.contact .contact-form #contact .form-group input::placeholder,
.contact .contact-form #contact .form-group textarea::placeholder {
  color: #cccc;
}
.contact .contact-form #contact .form-group button#submit strong {
  color: #000;
  background: #fff;
}
.contact .contact-form #contact .form-group button[type="submit"] b {
  background: #000;
}
.contact .contact-form #contact .form-group button[type="submit"] i {
  border-color: transparent transparent transparent #000;
}
.contact .contact-form #contact .form-group input[type="button"] {
  height: 65px;
  border: 1px solid #000;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 0 50px;
  background: #fff;
  color: #000;
  border: 1px solid #fff;
  transition: 200ms ease-in-out;
  border-radius: 40px;
}
.contact .contact-form #contact .form-group input[type="button"]:hover {
  background: #000;
  color: #fff;
}
span#c_error_message,
span#c_success_message {
  color: #fff !important;
}

/* FOOTER */
.footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 150px 0;
}
.footer .career {
  display: block;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
  padding-bottom: 100px;
  margin-bottom: 100px;
}
.dark-mode .footer .career {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.footer .career h6 {
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer .career h2 {
  font-size: 4vw;
  font-weight: 800;
  margin-bottom: 50px;
}
.footer .logo {
  display: block;
  margin: 0;
}
.footer .logo img {
  height: 60px;
}
.footer .block {
  visibility: "visible";
  animation-name: "fadeInUp";
}
.footer h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer .sub-footer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 100px;
  font-size: 13px;
}
.footer .sub-footer ul {
  display: inline-block;
  margin-left: 0;
  margin-right: auto;
  margin-bottom: 0;
  padding: 0;
}
.footer .sub-footer ul li {
  display: inline-block;
  margin-right: 20px;
  padding: 0;
  list-style: none;
}
.footer .sub-footer ul li a {
  display: block;
  position: relative;
  padding: 0 2px;
}
.footer .sub-footer ul li a:hover {
  text-decoration: none;
}
.footer .sub-footer ul li a:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: 8px;
  height: 1px;
  width: 0;
  transition: width 0s ease, background 0.25s ease;
}
.footer .sub-footer ul li a:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 8px;
  height: 1px;
  width: 0;
  background: #000;
  transition: width 0.25s ease;
}
.footer .sub-footer ul li a:hover:before {
  width: 100%;
  background: #000;
  transition: width 0.25s ease;
}
.footer .sub-footer ul li a:hover:after {
  width: 100%;
  background: transparent;
  transition: all 0s ease;
}
.footer .sub-footer span {
  margin-right: 0;
}
.footer .links {
  display: flex;
  flex-direction: column;
}
.footer .links .link {
  margin-bottom: 14px;
  max-width: fit-content;
}
.footer .links .link:last-child {
  margin-bottom: 0px;
}

.slider.home-banner {
  background: url(../images/home-banner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

section.featured-services {
  position: relative;
}
section.featured-services:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0px;
  left: 0;
  bottom: 0;
  background: url(../images/workBg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0;
  transition: 300ms ease-in-out;
}
body.color-dark section.featured-services:after {
  opacity: 0.2;
}
section.featured-services .titles {
  padding-right: 0;
}
.main-title {
  color: #bbbbbb;
  position: relative;
  display: block;
  font-style: normal;
  padding-left: 26px;
}
.main-title:after {
  content: "";
  position: absolute;
  left: 0;
  top: 12px;
  width: 20px;
  background: #bbbbbb;
  height: 2px;
}
section.featured-services .titles > h3 {
  font-weight: 700;
  font-size: 40px;
  line-height: 58px;
  margin-top: 19px;
}
section.featured-services .titles > h3 span {
  display: block;
}
.featured-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
}
.digital-services .featured-grid {
  background-color: #121212;
  border: none !important;
  transition: all 0.3s;
  border-radius: 0 !important;
}
.digital-services .featured-grid:hover {
  background-color: #1f1f1f;
}
.digital-services h4 {
  margin-bottom: 0;
  text-transform: unset !important;
}
.digital-services h4::after {
  display: none !important;
}
.digital-services .digital-services-img {
  background: #1f1f1f;
  display: inline-block;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.digital-services .featured-grid:hover .digital-services-img {
  background: #121212;
}
.digital-services .digital-services-img img {
  width: 30px;
}
.digital-services .featured-grid ul li {
  line-height: 40px !important;
  font-size: 16px !important;
  color: #c3c3c3;
}
section.featured-services .featured-grid {
  border: 1px solid #ffffff;
  padding: 4% 3%;
  border-radius: 5px;
  width: 31%;
}
section.featured-services .featured-grid h4 {
  margin-top: 24px;
  font-weight: 600;
  text-transform: uppercase;
  position: relative;
}
section.featured-services .featured-grid h4:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -12px;
  height: 3px;
  background: #fff;
  width: 60px;
}
section.featured-services .featured-grid ul {
  padding: 0;
  list-style: none;
  margin-top: 40px;
  margin-bottom: 0;
}
section.featured-services .featured-grid ul li {
  line-height: 30px;
  font-size: 14px;
}
.featured-inner.work-inner .featured-grid {
  border: 0;
  width: 21%;
  padding: 0;
}
.featured-inner.work-inner .featured-grid > span {
  width: 45px;
  height: 45px;
  background: #fff;
  color: #000;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 3px;
}
.featured-inner.work-inner .featured-grid h6 {
  font-weight: 600;
  margin: 30px 0 12px;
}
.featured-inner.work-inner .featured-grid p {
  font-size: 14px;
  opacity: 0.8;
}
.testimonial-section {
  width: 100%;
}
.testimonial-section .swiper-pagination {
  margin-top: 20px;
  position: static;
}
.testimonial-section .swiper-pagination-bullet svg circle {
  stroke: #000;
}
.testimonial-section .swiper-pagination-bullet:before {
  border: 3px solid rgba(0, 0, 0, 0.3);
}
.testimonial-section .swiper-pagination-bullet-active:after {
  background: #000;
}
/* .testimonial-grid {
    border-left: 2px solid #000;
    padding-left: 30px;
} */

.testimonial-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}
.pagination-sec {
  max-width: 284px !important;
  margin: 0 auto;
  position: relative;
}
.swiper-button-next,
.swiper-button-prev {
  background-size: unset;
  width: 38px;
  height: 38px;
  top: 50%;
}
.swiper-button-prev {
  left: 0;
}
.swiper-button-next {
  right: 0;
}
span.testimonial-img {
  width: 84px;
  height: 84px;
  overflow: hidden;
  border-radius: 50%;
}
.dark-mode span.testimonial-img {
  width: 60px;
  height: 60px;
}
span.testimonial-img img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.testimonial-author {
  font-size: 1.5rem;
  text-align: center;
}

span.testimonial-author b {
  display: block;
  font-weight: 500;
  text-align: center;
  color: #0c0c0c;
}
span.testimonial-author i {
  font-size: 14px;
  color: #0c0c0c;
}

span.testimonial-author i.main-title:after {
  background: #0c0c0c;
  top: 40%;
  display: none;
}
.testimonial-content {
  text-align: center;
  max-width: 840px;
  margin: 3rem auto 1.5rem;
}
.testimonial-content p {
  font-size: 1rem;
  line-height: 1.45;
}
.testimonial-content .custom-btn a {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  text-decoration: none;
  height: auto;
  padding: 0;
  background: transparent;
  border: 0;
  color: #000;
}
.testimonial-content .custom-btn span {
  background: #000;
  margin-top: 10px;
}
.testimonial-content .custom-btn i {
  border-color: transparent transparent transparent #000;
  margin-top: 6.5px;
}

.related-case-study {
  display: flex;
  flex-direction: column;
}
.related-case-study .large {
  flex: 1;
}

/*Case Study Page*/
.related-case-study.blue {
  background: #def6e7;
  /* background: #4ef0ff; */
}
.related-case-study.yellow {
  background: #f9eb7f;
  /* background: #fffb4e; */
}

/*link*/
.pointer {
  cursor: pointer;
}

/*owl Carousal*/
.logos .owl-carousel .item img {
  height: 30px;
}
.logos .owl-carousel .owl-nav {
  position: relative;
  text-align: center;
}
.logos .owl-carousel .owl-nav > button {
  width: 30px;
  height: 30px;
  background: #fff !important;
  color: #000 !important;
  border-radius: 50%;
  opacity: 0.75;
  margin: 10px 5px 0;
}
.logos .owl-carousel .owl-nav > button:hover {
  opacity: 1;
}

/* ANIMATIONS */
@keyframes countdown {
  from {
    stroke-dashoffset: 113px;
  }
  to {
    stroke-dashoffset: 0;
  }
}
/* MEDIUM FIXES */

@media screen and (min-width: 1700px) {
  .navigation-menu .inner {
    width: 1440px;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 1199px),
  only screen and (max-device-width: 1199px) {
  body {
    padding: 0;
  }

  .left-side {
    display: none;
  }

  .navbar {
    padding: 30px;
  }

  .slider {
    width: 100%;
    margin-right: 0;
  }

  .slider .swiper-container .slide-inner {
    padding: 0 60px;
  }

  .works .titles {
    padding-right: 0;
  }
}
/* RESPONSIVE TABLET FIXES */
@media only screen and (max-width: 991px),
  only screen and (max-device-width: 991px) {
  .slider .swiper-container .slide-inner {
    padding: 0 30px;
  }

  .preloader .inner .percentage {
    font-size: 50vh;
  }

  .navigation-menu .inner .side-menu {
    display: block;
  }

  .navbar .main-menu {
    display: none;
  }

  .navbar .phone {
    margin-right: auto;
  }

  /*.navigation-menu .inner .sides {
    display: none;
  }*/
  .slider .swiper-container .swiper-slide {
    width: 100% !important;
  }

  .slider:hover .swiper-container .swiper-slide {
    margin-left: 0;
  }

  .header .page-title h4 {
    padding-right: 0;
  }

  .titles {
    padding-right: 0;
  }

  .works ul li {
    width: 50%;
  }

  .our-awards ul li {
    width: 33%;
    float: none;
    display: inline-block;
    margin: 0 6%;
  }

  .our-awards ul li figure {
    border-right: none;
  }

  .our-awards ul li .odometer {
    margin-bottom: 40px;
  }

  .team figure {
    margin-bottom: 40px;
  }

  .logos ul li {
    width: 33.33333%;
  }

  .logos ul li:nth-child(n + 10) {
    display: none;
  }

  .our-awards .titles {
    padding: 0 5%;
  }

  .post-sticky .post-content {
    padding-right: 0;
  }

  .post-sticky .post-content .post-intro {
    display: none;
  }

  .blog {
    padding-top: 100px;
  }

  .blog .sidebar {
    width: 100%;
    margin-top: 100px;
  }

  .footer .sub-footer {
    margin-top: 60px;
  }

  .footer .block {
    margin-bottom: 40px;
  }

  .footer .career h2 {
    font-size: 6vw;
  }

  input[type="text"] {
    border-radius: 0 !important;
    -webkit-appearance: none;
  }

  textarea {
    border-radius: 0 !important;
    -webkit-appearance: none;
  }
}
/* RESPONSIVE MOBILE FIXES */
@media only screen and (max-width: 767px),
  only screen and (max-device-width: 767px) {
  .preloader .inner .percentage {
    font-size: 30vh;
  }
  .preloader .inner .logo img {
    height: 150px;
  }

  .navbar {
    padding: 30px 0px;
  }

  .navbar .logo a img {
    height: 48px;
  }

  .navbar .phone {
    display: none;
  }

  .navbar .burger .burger-icon {
    width: inherit;
    height: inherit;
    margin-left: 8px;
  }

  .slider .swiper-container .slide-inner {
    padding: 0 30px;
  }

  .slider .swiper-container .slide-inner h2 {
    font-size: 35px;
  }

  .recent-news .row {
    margin-left: -15px;
    margin-right: -15px;
  }

  .recent-news .col-lg-4 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .recent-news .col-12 {
    padding-left: 15px;
    padding-right: 15px;
  }

  .header .page-title h2 {
    font-size: 2.5rem;
  }

  .works ul li {
    width: 100%;
  }

  .works ul {
    width: 100%;
    margin: 0 !important;
  }

  .our-awards .titles {
    padding: 0;
  }

  .logos ul li {
    width: 50%;
  }

  .logos ul li:nth-child(9) {
    display: none;
  }

  .post-sticky .post-content {
    padding: 20px 30px;
  }

  .post-sticky .post-content .post-link {
    display: none;
  }

  .blog .post .post-content .post-title {
    font-size: 6vw;
  }

  .post-body .post-title {
    font-size: 6vw;
  }

  .post-navigation a:nth-child(1) {
    width: 100%;
    border-right: 0;
    border-bottom: 1px solid #eee;
  }

  .post-navigation a:nth-child(2) {
    width: 100%;
  }

  .work-with-us h2 {
    font-size: 2.5rem;
  }

  .logos ul li figure {
    height: 65px;
  }

  .logos ul li figure img {
    height: 65px;
  }

  .about-intro h2 {
    font-size: 8vw;
  }

  .main-services .titles {
    padding: 0;
  }

  .footer .career h2 {
    font-size: 2.5rem;
  }

  .footer .sub-footer ul {
    margin-bottom: 15px;
  }

  section.featured-services .titles {
    padding: 0 30px;
    margin-bottom: 10px;
  }
  section.featured-services .titles > h3 {
    font-size: 28px;
    line-height: 36px;
    margin-top: 9px;
  }
  section.featured-services .titles > h3 span {
    display: inline;
  }
  .featured-inner {
    padding: 0 45px;
  }
  section.featured-services .featured-grid {
    border: 1px solid #ffffff;
    padding: 30px;
    border-radius: 5px;
    width: 100%;
    margin-top: 20px;
  }
  section.featured-services .featured-grid h4 {
    font-size: 18px;
  }
  section.featured-services .featured-grid h4:after {
    height: 2px;
    width: 40px;
  }
  .recent-news {
    padding: 0 30px;
  }

  .testimonial-content p {
    font-size: 1rem;
  }
  span.testimonial-img {
    width: 40px;
    height: 40px;
  }
  /* span.testimonial-author {
    margin-left: 10px;
    width: calc(100% - 50px);
  } */
  span.testimonial-author i {
    font-size: 11px;
  }
  .featured-inner.work-inner .featured-grid {
    width: 46%;
  }
  .navigation-menu .inner .side-menu {
    float: none;
    width: 100%;
    height: auto;
    text-align: left;
  }
  .navigation-menu .inner .side-menu ul {
    float: none;
  }
  .navigation-menu .inner .side-menu ul li a {
    font-size: 24px;
    display: block;
    text-align: center;
  }
  .navigation-menu .inner .side-menu ul li a > span {
    font-size: 14px;
  }
  .navigation-menu .inner .sides {
    display: none;
  }

  .contact {
    padding-top: 20px;
  }
  .contact .contact-form #contact .form-group.form-group2 label {
    margin: 10px 0;
  }
  .contact .contact-form #contact .form-group.form-group2 label i {
    padding: 5px 10px;
    font-size: 13px;
  }
  .contact .contact-form #contact .form-group.form-group2 p {
    font-size: 20px;
  }
  .contact .contact-form #contact .form-group input {
    font-size: 20px;
  }
  .contact .contact-form #contact .form-group {
    margin-bottom: 25px;
  }

  .contact
    .contact-form
    #contact
    .form-group.form-group2
    label:hover
    i
    > .hover {
    top: 5px;
  }
  h1,
  .h1 {
    font-size: 7vw;
  }
}
@media only screen and (max-width: 460px),
  only screen and (max-device-width: 460px) {
  .preloader .inner .logo img {
    height: 140px;
  }

  .navbar {
    padding: 30px 20px;
  }
  .navbar .logo a img {
    height: 40px;
  }
  .navbar .hamburger-menu {
    margin-left: 10px;
  }
  .quote-btn {
    display: none;
  }
}
/*# sourceMappingURL=style.css.map */

.odometer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
}

.odometer-value {
  font-size: 50px;
  transition: transform 0.5s ease-out;
  transform: translateY(0);
}

.odometer-value.change {
  transform: translateY(-100%);
}

/* Services View */
.services-view {
}
.services-view .page-hdr {
  margin-bottom: 60px;
}
.services-view .services-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
}

/* Services Card */
.service-card {
  background-color: #121212;
  transition: all 0.3s;
  padding: 40px 30px;
  width: 100%;
}
.service-card:hover {
  background-color: #1f1f1f;
}
.service-card .services-img {
  background: #1f1f1f;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.service-card:hover .services-img {
  background: #121212;
}
.service-card .services-img img {
  width: 30px;
}

.service-card h4 {
  margin-top: 24px;
  margin-bottom: 0;
  font-weight: 600;
  position: relative;
  margin-bottom: 0;
  text-transform: unset !important;
  padding: 0 !important;
}

.service-card ul {
  padding: 0;
  list-style: none;
  margin-top: 40px;
  margin-bottom: 0;
}
.service-card ul li {
  line-height: 40px;
  font-size: 16px;
  color: #c3c3c3;
}
